console.log("%c\n Cloudflare %c\u767e\u5ea6\u4e91 %c\u5408\u4f5c\u8282\u70b9 %c\n 162.159.208.4 >%c> 162.159.208.103 %c\n 162.159.209.4 >%c> 162.159.209.103 %c\n 162.159.210.4 >%c> 162.159.210.103 %c\n 162.159.211.4 >%c> 162.159.211.103 \n","color:rgb(243, 128, 32);padding:5px 0;","color:#38f;padding:5px 0;","padding:5px 0;","color:#444;background:#eee;padding:5px 0;","color:#fff;background:#876;padding:5px 0;","color:#444;background:#eee;padding:5px 0;","color:#fff;background:#876;padding:5px 0;","color:#444;background:#eee;padding:5px 0;","color:#fff;background:#876;padding:5px 0;","color:#444;background:#eee;padding:5px 0;","color:#fff;background:#876;padding:5px 0;");
console.log("%c\n清除密钥激活: %c\nslmgr /upk && slmgr /ckms && slmgr /rearm","color:#38f;padding:5px 0;border:none","color:#444;background:#eee;padding:5px 0;");
console.log("%c\n设置KMS激活: %c\nslmgr /skms kms.03k.org && slmgr /ato","color:#38f;padding:5px 0;border:none","color:#444;background:#eee;padding:5px 0;");
console.log("%c ","background:url(https://cdn.jsdelivr.net/gh/Jay-Young/jsDelivrCDN@master/assets/images/hugo/cache/Konachan.com%20-%20305021%20sample.jpg) no-repeat center;background-size:cover;padding-left:100%;padding-bottom:55%;overflow:hidden;border-radius:10px;margin:5px 0");
console.log(
  "\u606d\u559c\u4f60\u53d1\u73b0\u4e86\u9690\u85cf\u5165\u53e3\uff01\n"
);
console.log("https://www.pornhub.com");

//搜索框脚本开始，来自seogo
var __encode = "seogo.me",
  _0xb483 = [
    "\x5F\x64\x65\x63\x6F\x64\x65",
    "\x68\x74\x74\x70\x3A\x2F\x2F\x77\x77\x77\x2E\x73\x6F\x6A\x73\x6F\x6E\x2E\x63\x6F\x6D\x2F\x6A\x61\x76\x61\x73\x63\x72\x69\x70\x74\x6F\x62\x66\x75\x73\x63\x61\x74\x6F\x72\x2E\x68\x74\x6D\x6C"
  ];
(function(_0xd642x1) {
  _0xd642x1[_0xb483[0]] = _0xb483[1];
})(window);
var __Ox1467e = [
  "\x63\x68\x65\x63\x6B\x65\x64",
  "\x69\x6E\x70\x75\x74\x5B\x6E\x61\x6D\x65\x3D\x22\x74\x79\x70\x65\x22\x5D\x5B\x76\x61\x6C\x75\x65\x3D\x22",
  "\x22\x5D",
  "\x71\x75\x65\x72\x79\x53\x65\x6C\x65\x63\x74\x6F\x72",
  "\x6C\x65\x6E\x67\x74\x68",
  "\x73\x2D\x63\x75\x72\x72\x65\x6E\x74",
  "\x72\x65\x6D\x6F\x76\x65",
  "\x63\x6C\x61\x73\x73\x4C\x69\x73\x74",
  "\x61\x64\x64",
  "\x70\x61\x72\x65\x6E\x74\x4E\x6F\x64\x65",
  "\x73\x75\x70\x65\x72\x53\x65\x61\x72\x63\x68",
  "\x73\x65\x74\x49\x74\x65\x6D",
  "\x6C\x6F\x63\x61\x6C\x53\x74\x6F\x72\x61\x67\x65",
  "\x67\x65\x74\x49\x74\x65\x6D",
  "\x74\x61\x72\x67\x65\x74",
  "\x76\x61\x6C\x75\x65",
  "\x74\x79\x70\x65",
  "\x66\x6F\x63\x75\x73",
  "\x6E\x65\x77\x57\x69\x6E\x64\x6F\x77",
  "\x70\x72\x65\x76\x65\x6E\x74\x44\x65\x66\x61\x75\x6C\x74",
  "",
  "\x61\x63\x74\x69\x6F\x6E",
  "\x6F\x70\x65\x6E",
  "\x68\x72\x65\x66",
  "\x69\x6E\x70\x75\x74\x5B\x6E\x61\x6D\x65\x3D\x22\x74\x79\x70\x65\x22\x5D\x3A\x63\x68\x65\x63\x6B\x65\x64",
  "\x64\x61\x74\x61\x2D\x70\x6C\x61\x63\x65\x68\x6F\x6C\x64\x65\x72",
  "\x67\x65\x74\x41\x74\x74\x72\x69\x62\x75\x74\x65",
  "\x70\x6C\x61\x63\x65\x68\x6F\x6C\x64\x65\x72",
  "\x73\x65\x74\x41\x74\x74\x72\x69\x62\x75\x74\x65",
  "\x5F\x62\x6C\x61\x6E\x6B",
  "\x72\x65\x6D\x6F\x76\x65\x41\x74\x74\x72\x69\x62\x75\x74\x65",
  "\x69\x6E\x70\x75\x74\x5B\x6E\x61\x6D\x65\x3D\x22\x74\x79\x70\x65\x22\x5D",
  "\x71\x75\x65\x72\x79\x53\x65\x6C\x65\x63\x74\x6F\x72\x41\x6C\x6C",
  "\x23\x73\x75\x70\x65\x72\x2D\x73\x65\x61\x72\x63\x68\x2D\x66\x6D",
  "\x23\x73\x65\x61\x72\x63\x68\x2D\x74\x65\x78\x74",
  "\x23\x73\x65\x74\x2D\x73\x65\x61\x72\x63\x68\x2D\x62\x6C\x61\x6E\x6B",
  "\x2E\x73\x65\x61\x72\x63\x68\x2D\x67\x72\x6F\x75\x70",
  "\x63\x68\x61\x6E\x67\x65",
  "\x61\x64\x64\x45\x76\x65\x6E\x74\x4C\x69\x73\x74\x65\x6E\x65\x72",
  "\x73\x75\x62\x6D\x69\x74"
];
!(function() {
  function _0x3bccx1() {
    _0x3bccx2(), _0x3bccx3(), _0x3bccx5(), _0x3bccx6();
  }
  function _0x3bccx2() {
    _0x3bccx17[__Ox1467e[0]] = _0x3bccxf();
  }
  function _0x3bccx3() {
    var _0x3bccx4 = document[__Ox1467e[3]](
      __Ox1467e[1] + _0x3bccxc() + __Ox1467e[2]
    );
    _0x3bccx4 && ((_0x3bccx4[__Ox1467e[0]] = !0), _0x3bccx7(_0x3bccx4));
  }
  function _0x3bccx5() {
    _0x3bccx12(_0x3bccx11());
  }
  function _0x3bccx6() {
    _0x3bccx13(_0x3bccx10());
  }
  function _0x3bccx7(_0x3bccx4) {
    for (var _0x3bccx8 = 0; _0x3bccx8 < _0x3bccx18[__Ox1467e[4]]; _0x3bccx8++) {
      _0x3bccx18[_0x3bccx8][__Ox1467e[7]][__Ox1467e[6]](__Ox1467e[5]);
    }
    _0x3bccx4[__Ox1467e[9]][__Ox1467e[9]][__Ox1467e[9]][__Ox1467e[7]][
      __Ox1467e[8]
    ](__Ox1467e[5]);
  }
  function _0x3bccx9(_0x3bccx4, _0x3bccx8) {
    window[__Ox1467e[12]][__Ox1467e[11]](__Ox1467e[10] + _0x3bccx4, _0x3bccx8);
  }
  function _0x3bccxa(_0x3bccx4) {
    return window[__Ox1467e[12]][__Ox1467e[13]](__Ox1467e[10] + _0x3bccx4);
  }
  function _0x3bccxb(_0x3bccx4) {
    (_0x3bccx19 = _0x3bccx4[__Ox1467e[14]]),
      _0x3bccx12(_0x3bccx11()),
      _0x3bccx13(_0x3bccx4[__Ox1467e[14]][__Ox1467e[15]]),
      _0x3bccx9(__Ox1467e[16], _0x3bccx4[__Ox1467e[14]][__Ox1467e[15]]),
      _0x3bccx16[__Ox1467e[17]](),
      _0x3bccx7(_0x3bccx4[__Ox1467e[14]]);
  }
  function _0x3bccxc() {
    var _0x3bccx8 = _0x3bccxa(__Ox1467e[16]);
    return _0x3bccx8 || _0x3bccx4[0][__Ox1467e[15]];
  }
  function _0x3bccxd(_0x3bccx4) {
    _0x3bccx9(__Ox1467e[18], _0x3bccx4[__Ox1467e[14]][__Ox1467e[0]] ? 1 : -1),
      _0x3bccx14(_0x3bccx4[__Ox1467e[14]][__Ox1467e[0]]);
  }
  function _0x3bccxe(_0x3bccx4) {
    return (
      _0x3bccx4[__Ox1467e[19]](),
      __Ox1467e[20] == _0x3bccx16[__Ox1467e[15]]
        ? (_0x3bccx16[__Ox1467e[17]](), !1)
        : (_0x3bccx13(_0x3bccx10() + _0x3bccx16[__Ox1467e[15]]),
          _0x3bccx14(_0x3bccxf()),
          _0x3bccxf()
            ? window[__Ox1467e[22]](_0x3bccx8[__Ox1467e[21]], +new Date())
            : (location[__Ox1467e[23]] = _0x3bccx8[__Ox1467e[21]]),
          void 0)
    );
  }
  function _0x3bccxf() {
    var _0x3bccx4 = _0x3bccxa(__Ox1467e[18]);
    return _0x3bccx4 ? 1 == _0x3bccx4 : !0;
  }
  function _0x3bccx10() {
    return document[__Ox1467e[3]](__Ox1467e[24])[__Ox1467e[15]];
  }
  function _0x3bccx11() {
    return document[__Ox1467e[3]](__Ox1467e[24])[__Ox1467e[26]](__Ox1467e[25]);
  }
  function _0x3bccx12(_0x3bccx4) {
    _0x3bccx16[__Ox1467e[28]](__Ox1467e[27], _0x3bccx4);
  }
  function _0x3bccx13(_0x3bccx4) {
    _0x3bccx8[__Ox1467e[21]] = _0x3bccx4;
  }
  function _0x3bccx14(_0x3bccx4) {
    _0x3bccx4
      ? (_0x3bccx8[__Ox1467e[14]] = __Ox1467e[29])
      : _0x3bccx8[__Ox1467e[30]](__Ox1467e[14]);
  }
  var _0x3bccx15,
    _0x3bccx4 = document[__Ox1467e[32]](__Ox1467e[31]),
    _0x3bccx8 = document[__Ox1467e[3]](__Ox1467e[33]),
    _0x3bccx16 = document[__Ox1467e[3]](__Ox1467e[34]),
    _0x3bccx17 = document[__Ox1467e[3]](__Ox1467e[35]),
    _0x3bccx18 = document[__Ox1467e[32]](__Ox1467e[36]),
    _0x3bccx19 = _0x3bccx4[0];
  for (
    _0x3bccx1(), _0x3bccx15 = 0;
    _0x3bccx15 < _0x3bccx4[__Ox1467e[4]];
    _0x3bccx15++
  ) {
    _0x3bccx4[_0x3bccx15][__Ox1467e[38]](__Ox1467e[37], _0x3bccxb);
  }
  _0x3bccx17[__Ox1467e[38]](__Ox1467e[37], _0x3bccxd),
    _0x3bccx8[__Ox1467e[38]](__Ox1467e[39], _0x3bccxe);
})();
//搜索框脚本结束

//自动更新版权年份开始，基于客户端本地时间
document.getElementById("copyright").innerText =
  "© 2019-" + new Date().getFullYear();
//自动更新版权年份结束

//网站运行时间开始，来自魔米导航
var now = new Date();
function createtime() {
  var grt = new Date("01/24/2019 00:00:00");
  now.setTime(now.getTime() + 250);
  days = (now - grt) / 1000 / 60 / 60 / 24;
  dnum = Math.floor(days);
  hours = (now - grt) / 1000 / 60 / 60 - 24 * dnum;
  hnum = Math.floor(hours);
  if (String(hnum).length == 1) {
    hnum = "0" + hnum;
  }
  minutes = (now - grt) / 1000 / 60 - 24 * 60 * dnum - 60 * hnum;
  mnum = Math.floor(minutes);
  if (String(mnum).length == 1) {
    mnum = "0" + mnum;
  }
  seconds =
    (now - grt) / 1000 - 24 * 60 * 60 * dnum - 60 * 60 * hnum - 60 * mnum;
  snum = Math.round(seconds);
  if (String(snum).length == 1) {
    snum = "0" + snum;
  }
  document.getElementById("siteLifeTime").innerHTML =
    "本站已上线运行：<font style=color:#C40000>" +
    dnum +
    "</font> 天 <font style=color:#C40000>" +
    hnum +
    "</font> 小时 <font style=color:#C40000>" +
    mnum +
    "</font> 分 <font style=color:#C40000>" +
    snum +
    "</font> 秒";
}
setInterval("createtime()", 250);
//网站运行时间结束

//自动夜间模式

(function() {
  if (
    document.cookie.replace(
      /(?:(?:^|.*;\s*)night\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) === ""
  ) {
    if (new Date().getHours() > 22 || new Date().getHours() < 6) {
      document.body.classList.add("night");
      document.cookie = "night=1;path=/";
      console.log("夜间模式开启");
    } else {
      document.body.classList.remove("night");
      document.cookie = "night=0;path=/";
      console.log("夜间模式关闭");
    }
  } else {
    var night =
      document.cookie.replace(
        /(?:(?:^|.*;\s*)night\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      ) || "0";
    if (night == "0") {
      document.body.classList.remove("night");
    } else if (night == "1") {
      document.body.classList.add("night");
    }
  }
})();

//夜间模式提示文字
function switchNightModePromot() {
  var night =
    document.cookie.replace(
      /(?:(?:^|.*;\s*)night\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) || "0";
  if (night == "0") {
    document.getElementById("swicthNightMode").title = "切换夜间模式";
  } else {
    document.getElementById("swicthNightMode").title = "返回白天模式";
  }
}
setInterval("switchNightModePromot()", 250);

//夜间模式切换
function switchNightMode() {
  var night =
    document.cookie.replace(
      /(?:(?:^|.*;\s*)night\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ) || "0";
  if (night == "0") {
    document.body.classList.add("night");
    document.cookie = "night=1;path=/";
    console.log("夜间模式开启");
  } else {
    document.body.classList.remove("night");
    document.cookie = "night=0;path=/";
    console.log("夜间模式关闭");
  }
}

//全屏切换
//控制全屏
function enterfullscreen() {
  //进入全屏
  $("#fullscreen").html(" ");
  var docElm = document.documentElement;
  //W3C
  if (docElm.requestFullscreen) {
    docElm.requestFullscreen();
  }
  //FireFox
  else if (docElm.mozRequestFullScreen) {
    docElm.mozRequestFullScreen();
  }
  //Chrome等
  else if (docElm.webkitRequestFullScreen) {
    docElm.webkitRequestFullScreen();
  }
  //IE11
  else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
}
function exitfullscreen() {
  //退出全屏
  $("#fullscreen").html(" ");
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

var a = 0;
$("#fullscreen").on("click", function() {
  a++;
  a % 2 == 1 ? enterfullscreen() : exitfullscreen();
});

function fk_upWard() {
  var g = window.$;
  if (g) {
    $("body,html").animate({ scrollTop: 0 });
  } else {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }
}
